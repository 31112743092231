<template>
  <div class="body">
    <div class="authentication">
    </div>
    <div class="scan-img">
      <van-image
          class="scan-img"
          :src="bgUrl"
      ></van-image>
    </div>
	<div style="text-align: center;margin-top: 20px;margin-bottom: 100px;font-weight: bold;font-size: 18px;">
		已绑定：{{phoneShow}}
	</div>
	<div style="bottom: 10px;">
		<van-button round type="primary" size="large" @click="unbindPhone">解除绑定</van-button>
	</div>
  </div>
</template>

<script>
	import request from "@/api/index.js";
export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
		openId: '',
		phone: '',
		phoneShow: ''
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/auth_success.png")
    }
  },
  watch: {
  },
  created() {
	this.openId = this.$route.query.openId
	this.phone = this.$route.query.phone
	this.phoneShow = this.phone.substr(0, 3) + '****' + this.phone.substring(7)
  },
  mounted() {

  },
  onLoad(options) {

  },
  methods: {
    recertification(){
      this.$router.push({path: '/authentication'})
    },
    jumpAuthentication(){
      wx.miniProgram.navigateBack({})
    },
	unbindPhone(){
		this.$dialog.confirm({
			title: '提示',
			message: '是否解除当前手机号码绑定？',
		}).then(() => {
		    // on confirm
			let data={
			  phone: this.phone,
			  openId: this.openId
			}
			request.post(process.env.VUE_APP_GZ_PHOENIX_URL + '/wechat/publicAccount/unbindOpenIdWithPhone', data).then(res=>{
				if(res.code == 200){
					this.$toast.success('解除绑定成功')
					this.$router.push({path: '/gzBindPhoneTemplate'})
				}else{
					this.$toast.fail(res.msg)
				}
			})
		}).catch(() => {
		    // on cancel
		});

	}
  },
}
</script>
<style lang='scss' scoped>
.body{
  background-color: white;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:120px;
  font-size: 25px;
  font-weight: bold;
}
.scan-img{
  opacity: 0.9;
  width: 10rem;
  height:10rem;
  margin: auto;
}
.posture{
  opacity: 0.9;
  width: 20rem;
  height:10rem;
  margin: auto;
}
.button{
  margin-top:30px;
}
</style>
